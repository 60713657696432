import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { css } from "@emotion/react";
import { FileDrop } from "react-file-drop";
import { rgba } from "polished";
import { filesize } from "filesize";
import { useFilePicker } from "use-file-picker";
import Theme from "../Theme";
import List, { ListItem } from "../List";
import FilesGallery from "../Preview";
import {
  FilePointer,
  LocalFilePointer,
  UploadedFilePointer,
  createLocalFilePointer,
  filePointerToString,
  isSameFilePointer,
  stringToFilePointer,
} from "../FilePointer";
import {
  cacheUploadedFilePointer,
  filePointerToFileDescriptor,
} from "../FileDescriptor";
import Await from "../Await";
import { labelPerFileCategory } from "../FileCategory";
import FieldProps from "./FieldProps";
import FieldWrapper from "./components/FieldWrapper";
import { useFieldId, useFieldValidity } from "./utilities";
import T from "@hpo/client/components/text/Text";
import { useServerSdk } from "@hpo/client/navigators/RootNavigator/services/ServerSdk";
import Button from "@hpo/client/components/Button";
import Divider from "@hpo/client/components/Divider";
import Spinner from "@hpo/client/components/Spinner";

type FilesFieldProps = FieldProps<Array<string> | null> & {
  fileDescription?: string;
  viewerHeight?: number;
  maximum?: number;
};

type UploadProgress =
  | { status: "uploading"; progress: number }
  | { status: "uploaded"; result: string }
  | { status: "error"; error: unknown };

export default function FilesField(props: FilesFieldProps) {
  const { disabled = false, readonly = false } = props;

  const isFileActive = !disabled && !readonly;

  if (isFileActive) {
    return <FilesFieldActive {...props} />;
  } else {
    return <FilesFieldInactive {...props} />;
  }
}

export function FilesFieldActive(props: FilesFieldProps) {
  const {
    value,
    required = false,
    errorMessage,
    onChange,
    placeholder,
    maximum = Infinity,
  } = props;

  const server = useServerSdk();

  const id = useFieldId(props.id);

  const { openFilePicker, plainFiles } = useFilePicker({
    multiple: true,
  });

  const validity = useFieldValidity(
    value,
    required,
    () => value === null || value === undefined || value.length === 0,
    errorMessage,
  );

  const [valuePointers, setValuePointers] = useState(() =>
    (value || []).map(stringToFilePointer),
  );

  useEffect(() => {
    setValuePointers((value || []).map(stringToFilePointer));
  }, [value]);

  const [fieldPointers, setFieldPointers] = useState<Array<LocalFilePointer>>(
    () => [],
  );

  const displayedPointers = useMemo(
    () => [...valuePointers, ...fieldPointers],
    [valuePointers, fieldPointers],
  );

  const [uploads, setUplaods] = useState<Record<string, UploadProgress>>(
    () => ({}),
  );

  const addFile = useCallback(async (file: File) => {
    const newFilePointer = await createLocalFilePointer(file);
    setFieldPointers((current) => {
      const existing = current.find((p) =>
        isSameFilePointer(p, newFilePointer),
      );
      if (existing) return current;
      return [...current, newFilePointer];
    });
  }, []);

  const removeFile = useCallback((pointer: FilePointer) => {
    if (pointer.type === "local-file") {
      setFieldPointers((current) =>
        current.filter((p) => !isSameFilePointer(p, pointer)),
      );
    } else {
      setValuePointers((current) =>
        current.filter((p) => !isSameFilePointer(p, pointer)),
      );
    }
  }, []);

  useEffect(() => {
    console.log("NAMES", plainFiles.map((p) => p.name).join(" "));
    plainFiles.forEach((file) => void addFile(file));
  }, [plainFiles.map((p) => p.name).join(" ")]);

  const upload = useCallback(
    async (pointer: LocalFilePointer) => {
      setUplaods((c) => ({
        ...c,
        [pointer.hash]: { status: "uploading", progress: 0 },
      }));
      try {
        const result = await server.uploadFile(pointer.file);
        setUplaods((c) => ({
          ...c,
          [pointer.hash]: { status: "uploaded", result },
        }));
      } catch (err) {
        setUplaods((c) => ({
          ...c,
          [pointer.hash]: { status: "error", error: err },
        }));
      }
    },
    [server],
  );

  useEffect(() => {
    fieldPointers.forEach((pointer) => {
      if (uploads[pointer.hash]) return;
      void upload(pointer);
    });
  }, [fieldPointers, uploads, upload]);

  const onDrop = useCallback(
    async (files: FileList | null, e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDragOver(false);
      if (files) {
        [...files].forEach((f) => void addFile(f));
      }
    },
    [],
  );

  useEffect(() => {
    fieldPointers.forEach((p) => {
      const upload = uploads[p.hash];
      if (!upload) return;
      if (upload.status !== "uploaded") return;
      const newFilePointer: UploadedFilePointer = {
        type: "uploaded-file",
        jwt: upload.result,
      };
      cacheUploadedFilePointer(newFilePointer, p);
      removeFile(p);
      setValuePointers((current) => [...current, newFilePointer]);
    });
  }, [fieldPointers, uploads, removeFile]);

  useEffect(() => {
    if (!onChange) return;
    const outsideHash = (value || []).join(" ");
    const insideHash = valuePointers.map(filePointerToString).join(" ");
    if (outsideHash === insideHash) return;
    if (valuePointers.length === 0) onChange(null);
    else onChange(valuePointers.map(filePointerToString));
  }, [onChange, valuePointers, fieldPointers]);

  // Maximum
  useEffect(() => {
    if (displayedPointers.length <= maximum) return;
    let toRemove = displayedPointers.length - maximum;
    setValuePointers((current) => {
      const dropped = current.splice(0, toRemove);
      if (dropped.length > 0) {
        toRemove = toRemove - dropped.length;
        return [...current];
      } else {
        return current;
      }
    });
    setFieldPointers((current) => {
      const dropped = current.splice(0, toRemove);
      if (dropped.length > 0) {
        toRemove = toRemove - dropped.length;
        return [...current];
      } else {
        return current;
      }
    });
  }, [valuePointers, fieldPointers, displayedPointers, maximum]);

  const onTargetClick = useCallback(() => {
    openFilePicker();
  }, [openFilePicker]);

  const fileZoneCss = css({
    flex: 1,
  });

  const filesCss = css({
    position: "relative",
    zIndex: 1,
  });

  const [dragOver, setDragOver] = useState<boolean>(false);

  const initialEmptyCss = css({
    height: 180,
    position: "relative",
  });

  let dropzoneCss = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 10,
    left: 10,
    right: 10,
    bottom: 10,
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: rgba(Theme.color, 0.5),
    borderRadius: 6,
    flex: 1,
    color: Theme.color,
    transition: "all 200ms",
    cursor: "pointer",
    zIndex: 20,
    flexDirection: "column",
    "&:hover": {
      background: rgba(Theme.color, 0.1),
    },
  });

  const filesDropZoneCss = css(dropzoneCss, {
    opacity: dragOver ? 1 : 0,
    pointerEvents: "none",
    background: rgba(Theme.color, 0.1),
  });

  if (dragOver) {
    dropzoneCss = css(dropzoneCss, {
      background: rgba(Theme.color, 0.1),
    });
  }

  const selectionCss = css({
    transition: "all 200ms",
    opacity: dragOver ? 0 : 1,
  });

  const appendCss = css({
    display: "flex",
    justifyContent: "center",
    padding: 10,
  });

  let node: ReactNode;
  if (displayedPointers.length === 0) {
    node = (
      <div css={fileZoneCss}>
        <FileDrop
          onTargetClick={onTargetClick}
          onDragOver={() => setDragOver(true)}
          onDragLeave={() => setDragOver(false)}
          onDrop={onDrop}
        >
          <div css={initialEmptyCss}>
            <div css={dropzoneCss}>
              <Button label="Séléctionnez vos documents" />
              <T> ou glissez/desposez les dans cette zone</T>
              <T style="minor">{placeholder}</T>
            </div>
          </div>
        </FileDrop>
      </div>
    );
  } else {
    node = (
      <div css={filesCss}>
        <FileDrop
          onDragOver={() => setDragOver(true)}
          onDragLeave={() => setDragOver(false)}
          onDrop={onDrop}
        >
          <div css={filesDropZoneCss}>
            <T>Déposez vos d'autres fichiers ici</T>
          </div>
          <div css={selectionCss}>
            <FilesGallery files={displayedPointers} size={300} />
            <Divider />
            <List
              noFrame
              data={displayedPointers}
              renderItem={(pointer) => {
                const descriptor = filePointerToFileDescriptor(pointer);
                return (
                  <Await promise={descriptor}>
                    {(d) => {
                      let help: string = `${labelPerFileCategory[d.category]} (${filesize(d.bytes, { round: 0 })})`;
                      let right: ReactNode;

                      if (pointer.type === "local-file") {
                        const upload = uploads[pointer.hash] || {
                          status: "uploading",
                          progress: 0,
                        };
                        if (upload.status === "uploading") {
                          help = `Chargement en cours... ${Math.round(upload.progress * 100)}%`;
                          right = <Spinner />;
                        } else if (upload.status === "error") {
                          help = `Fichier invalide`;
                          right = <Button style="discreet" icon="error" />;
                        }
                      }

                      return (
                        <ListItem
                          label={d.name}
                          oneLineLabel
                          help={help}
                          right={
                            <Fragment>
                              {right}
                              <Button
                                style="discreet"
                                icon="close"
                                onClick={() => removeFile(pointer)}
                              />
                            </Fragment>
                          }
                        />
                      );
                    }}
                  </Await>
                );
              }}
            />
            <Divider />
            <div css={appendCss}>
              <Button
                label={
                  displayedPointers.length >= maximum
                    ? maximum === 1
                      ? "Changer ce document"
                      : "Changer ces documents"
                    : "Ajouter des documents"
                }
                onClick={onTargetClick}
              />
            </div>
          </div>
        </FileDrop>
      </div>
    );
  }

  return (
    <FieldWrapper {...props} id={id} validity={validity}>
      {node}
    </FieldWrapper>
  );
}

export function FilesFieldInactive(props: FilesFieldProps) {
  const { value, required = false, errorMessage, viewerHeight = 500 } = props;

  const id = useFieldId(props.id);

  const validity = useFieldValidity(
    value,
    required,
    () => value === null || value === undefined || value.length === 0,
    errorMessage,
  );

  return (
    <FieldWrapper {...props} id={id} validity={validity}>
      <FilesGallery files={value || []} size={viewerHeight} />
    </FieldWrapper>
  );
}
