import { sortBy, uniqBy } from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { rgba } from "polished";
import Table from "../../ConventionNavigator/PaymentScreen/Table";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import {
  BeneficiaryAchievement,
  BeneficiaryAchievementEdition,
} from "@hpo/client/models/Achievement";
import T from "@hpo/client/components/text/Text";
import { BeneficiaryPaymentDetails } from "@hpo/client/models/BeneficiaryPayment";
import { ReceiptCreation } from "@hpo/client/models/Receipt";
import { getReceiptsForRequestTypeAndPaymentFragment } from "@hpo/client/utilities/Receipts";
import Sections from "@hpo/client/utilities/Sections";
import Spacer from "@hpo/client/utilities/Spacer";
import Stepper from "@hpo/client/utilities/Stepper2";
import TextField from "@hpo/client/utilities/fields/TextField";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import FilesField from "@hpo/client/utilities/fields/FilesField";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import Units from "@hpo/client/utilities/Units";
import IndicatorOrigin from "@hpo/client/utilities/enums/IndicatorOrigin";
import { IndicatorSummary } from "@hpo/client/models/Indicator";
import { FullfilledMarker, MarkerRequirement } from "@hpo/client/models/Marker";
import MarkerType from "@hpo/client/utilities/enums/MarkerType";
import Intersperse from "@hpo/client/components/Intersperse";
import Clickable from "@hpo/client/components/Clickable";
import Theme from "@hpo/client/utilities/Theme";

type AchievementStepProps = {
  payment: BeneficiaryPaymentDetails;
  achievment: BeneficiaryAchievement;
  onSubmit: (request: BeneficiaryAchievementEdition) => unknown;
};

export default function AchievementStep(props: AchievementStepProps) {
  const { payment, achievment, onSubmit } = props;

  const [receipts, setReceipts] = useState<Array<ReceiptCreation>>([]);
  const [markers, setMarkers] = useState<Array<MarkerRequirement>>([]);

  const allPayments = useMemo(() => payment.achievements.map((a) => a.id), []);

  const [requestedAmount, setRequestedAmount] = useState<number | null>(
    achievment.requestedAmount || achievment.availableAmount,
  );
  const [notes, setNotes] = useState<string | null>(achievment.requestNotes);

  const notesRequired =
    payment.fragment === "deposit1" &&
    requestedAmount !== null &&
    requestedAmount < achievment.grantedAmount;

  const onStepSubmit = useCallback(async () => {
    const emptyFiles = receipts.filter((c) => c.files.length === 0);
    if (emptyFiles.length)
      throw new MessageException(
        "Veillez à fournir des fichiers pour l'ensemble des documents demandés",
        null,
      );
    const emptyMarkers = markers.filter((m) => m.value === null);
    if (emptyMarkers.length)
      throw new MessageException(
        "Veillez à fournir des valeurs demandées pour les différents indicateurs",
        null,
      );
    if (requestedAmount === null)
      throw new MessageException(
        "Veillez à renseigner le montant demandé",
        null,
      );
    if (notesRequired && !notes)
      throw new MessageException(
        'Veillez à motiver le montant demandé dans le champ “Observations"',
        null,
      );
    await onSubmit({
      id: achievment.id,
      receipts,
      markers: markers as Array<FullfilledMarker>,
      amount: requestedAmount,
      notes,
    });
  }, [receipts, requestedAmount, notesRequired, markers, notes, onSubmit]);

  return (
    <Stepper.Step
      id={achievment.id}
      title={`Déclarez votre avancement dans le projet "${getProjectLabel(achievment.funding.project)}"`}
      details={`Rassemblez les différents justificatifs relatifs au projet "${getProjectLabel(achievment.funding.project)}" et déclarez le montant de la subvention que vous souhaitez obtenir pour ce projet.`}
      onSubmit={onStepSubmit}
      concurentWith={allPayments}
    >
      <AchievementReceipts
        payment={payment}
        achievment={achievment}
        onChange={setReceipts}
      />
      <Spacer />
      <AchievementIndicators
        payment={payment}
        achievment={achievment}
        onChange={setMarkers}
      />
      <Spacer />
      <T style="section">Montant et observations</T>
      <Spacer />
      <WithHelp
        text={`Vous pouvez demander jusqu'à ${Units.euro.display(achievment.grantedAmount)}. Si vous demandez moins, justifiez le montant demandé dans le champ "Observations".`}
        inactive={payment.fragment !== "deposit1"}
      >
        <NumberField
          label="Montant demandé"
          value={requestedAmount}
          onChange={setRequestedAmount}
          unit="euro"
          max={achievment.availableAmount}
          allowZero
        />
      </WithHelp>
      <Spacer />
      <TextField
        label="Observations"
        value={notes}
        onChange={setNotes}
        required={notesRequired}
      />
    </Stepper.Step>
  );
}

type AchievementReceiptsProps = {
  payment: BeneficiaryPaymentDetails;
  achievment: BeneficiaryAchievement;
  onChange: (receipts: Array<ReceiptCreation>) => unknown;
};

function AchievementReceipts(props: AchievementReceiptsProps) {
  const { payment, achievment, onChange } = props;

  const projectType = achievment.funding.project.type;

  const descriptors = useMemo(
    () =>
      getReceiptsForRequestTypeAndPaymentFragment(
        projectType,
        payment.fragment,
      ),
    [payment.fragment, projectType],
  );

  const [receipts, setReceipts] = useState<Array<ReceiptCreation>>(() => {
    return descriptors.map((r) => {
      const matching = achievment.receipts.find((a) => a.key === r.key);
      return {
        achievment: achievment.id,
        key: r.key,
        files: matching ? matching.files : [],
        notes: matching ? matching.notes : null,
      };
    });
  });

  const setFiles = useCallback((key: string, files: Array<string> | null) => {
    if (!files) return;
    setReceipts((current) => {
      return current.map((creation) =>
        creation.key === key ? { ...creation, files } : creation,
      );
    });
  }, []);

  const setReceiptNotes = useCallback((key: string, notes: string | null) => {
    setReceipts((current) => {
      return current.map((creation) =>
        creation.key === key ? { ...creation, notes } : creation,
      );
    });
  }, []);

  useEffect(() => {
    onChange(receipts);
  }, [receipts, onChange]);

  const templateCss = css({
    textDecoration: "underline",
    color: Theme.color,
    borderRadius: 6,
    padding: 6,
    transition: "all 100ms",
    "&:hover": {
      backgroundColor: rgba(Theme.color, 0.2),
    },
  });

  return (
    <Fragment>
      <T style="section">Justificatifs</T>
      <Spacer />
      <Sections>
        {receipts.map((receipt) => {
          const descriptor = descriptors.find((r) => r.key === receipt.key);
          if (!descriptor) throw new Error("Missinf receipt");
          return (
            <Fragment key={receipt.key}>
              <Sections.Section
                id={receipt.key}
                key={receipt.key}
                title={descriptor.label}
              >
                <WithHelp
                  inactive={descriptor.fillouts === null}
                  title={
                    descriptor.fillouts
                      ? descriptor.fillouts.length === 1
                        ? "Voici le document à compléter"
                        : "Voici les documents à compléter"
                      : ""
                  }
                  text={
                    descriptor.fillouts ? (
                      descriptor.fillouts.length === 1 ? (
                        <Fragment>
                          <Spacer />
                          <T>Téléchargez et complétez ce document :</T>
                          <Spacer scale={0.5} />
                          <ul>
                            {(descriptor.fillouts || []).map((f) => (
                              <li key={f}>
                                <Clickable
                                  href={"/fillouts/" + f}
                                  css={templateCss}
                                >
                                  {f}
                                </Clickable>
                              </li>
                            ))}
                          </ul>
                          <Spacer />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Spacer />
                          <T>
                            Téléchargez et complétez les documents listés
                            ci-dessous.
                          </T>
                          <Spacer scale={0.5} />
                          <ul>
                            {(descriptor.fillouts || []).map((f) => (
                              <li key={f}>
                                <a href={"/fillouts/" + f} css={templateCss}>
                                  {f}
                                </a>
                              </li>
                            ))}
                          </ul>
                          <Spacer />
                        </Fragment>
                      )
                    ) : (
                      <Fragment></Fragment>
                    )
                  }
                >
                  <FilesField
                    value={receipt.files}
                    onChange={(f) => setFiles(receipt.key, f)}
                    label={"Documents"}
                    required
                  />
                </WithHelp>
                <Spacer />
                <TextField
                  textArea
                  value={receipt.notes}
                  onChange={(v) => setReceiptNotes(receipt.key, v)}
                  label="Observations sur ces documents"
                />
                <Spacer />
              </Sections.Section>
            </Fragment>
          );
        })}
      </Sections>
    </Fragment>
  );
}

type AchievementIndicatorsProps = {
  payment: BeneficiaryPaymentDetails;
  achievment: BeneficiaryAchievement;
  onChange: (markers: Array<MarkerRequirement>) => unknown;
};

function AchievementIndicators(props: AchievementIndicatorsProps) {
  const { achievment, onChange } = props;
  const { indicators, markers } = achievment;

  const columns = useMemo(() => {
    const columns = markers.map((v) => ({ type: v.type, period: v.period }));
    const uniqs = uniqBy(columns, (c) => `${c.type}-${c.period}`);
    const sorted = sortBy(
      uniqs,
      (c) => achievment.funding.project.periods.indexOf(c.period),
      (c) => (c.type === MarkerType.Goal ? 1 : 2),
    );
    return sorted;
  }, [markers]);

  const [values, setValues] = useState<Record<string, number | null>>(() => {
    return Object.fromEntries(markers.map((i) => [getStatKey(i), i.value]));
  });

  const setMarker = useCallback(
    (v: MarkerRequirement, value: number | null) => {
      setValues((current) => ({ ...current, [getStatKey(v)]: value }));
    },
    [],
  );

  useEffect(() => {
    onChange(markers.map((m) => ({ ...m, value: values[getStatKey(m)] })));
  }, [markers, values, onChange]);

  const fromBeneficiary = useMemo(
    () => indicators.filter((i) => i.origin === IndicatorOrigin.Beneficiary),
    [indicators],
  );

  const fromBeneficiaryNode = fromBeneficiary.length ? (
    <Fragment>
      <T style="section">Indicateurs d'évaluation</T>
      <Spacer />
      <Table>
        <thead>
          <tr>
            <th>Libellé</th>
            {columns.map((c) => (
              <th key={c.period + c.type}>
                <T style="minor" oneLine>
                  {c.type === "goal" ? "Objectif pour" : "Réalisé en"}
                </T>
                {c.period}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {fromBeneficiary.map((indicator) => {
            return (
              <IndicatorLine
                key={indicator.id}
                indicator={indicator}
                markers={markers}
                columns={columns}
                values={values}
                onChange={(c, v) => setMarker(c, v)}
              />
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  ) : null;

  const fromInstructor = useMemo(
    () => indicators.filter((i) => i.origin === IndicatorOrigin.Instructor),
    [indicators],
  );

  const fromInstructorNode = fromInstructor.length ? (
    <Fragment>
      <T style="section">Indicateurs d'impact</T>
      <Spacer />
      <Table>
        <thead>
          <tr>
            <th>Libellé</th>
            {columns.map((c) => (
              <th key={c.period + c.type}>
                <T style="minor" oneLine>
                  {c.type === "goal" ? "Objectif pour" : "Réalisé en"}
                </T>
                {c.period}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {fromInstructor.map((indicator) => {
            return (
              <IndicatorLine
                key={indicator.id}
                indicator={indicator}
                markers={markers}
                columns={columns}
                values={values}
                onChange={(c, v) => setMarker(c, v)}
              />
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  ) : null;

  return (
    <Intersperse between={() => <Spacer />}>
      {fromBeneficiaryNode}
      {fromInstructorNode}
    </Intersperse>
  );
}

type IndicatorColumn = {
  type: "goal" | "actual";
  period: string;
};

type IndicatorLineProps = {
  indicator: IndicatorSummary;
  markers: Array<MarkerRequirement>;
  values: Record<string, number | null>;
  columns: Array<IndicatorColumn>;
  onChange: (stat: MarkerRequirement, value: number | null) => unknown;
};

function IndicatorLine(props: IndicatorLineProps) {
  const { indicator, markers, columns, values, onChange } = props;
  return (
    <tr key={indicator.id}>
      <td>{indicator.label}</td>
      {columns.map((c) => {
        const key = c.period + c.type;
        const marker = markers.find(
          (s) =>
            s.indicator === indicator.id &&
            s.type === c.type &&
            s.period === c.period,
        );
        if (!marker) return <td key={key}></td>;
        const value = values[getStatKey(marker)];
        return (
          <td key={c.period + c.type}>
            {!marker.editable ? (
              <T>{Units[indicator.unit].display(marker.value)}</T>
            ) : (
              <NumberField
                value={value}
                onChange={(v) => onChange(marker, v)}
                unit={indicator.unit}
                allowZero
              />
            )}
          </td>
        );
      })}
    </tr>
  );
}

const getStatKey = (i: MarkerRequirement) =>
  `${i.indicator} ${i.type} ${i.period}`;
